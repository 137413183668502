.contacts{
  &-map{
    border: 1px solid var(--dark-color);
    margin-top: 130px;
  }
  &__item{
    display: flex;
    align-items: center;
    &:not(:last-child){
      margin-bottom: 35px;
    }
    &_title{
      width: 265px;
    }
    &_tel:not(:last-child){
      margin-right: 20px;
    }
    & a{
      color: var(--dark-color);
    }
    &_none{
      display: none;
    }
  }
}

.urid{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__content{
    margin-left: 20px;
  }
}
