// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Raleway", sans-serif;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --accent-color: #FFA500;
  --text-color: #1B2C3E;
  --dark-color: #1B2C3E;
}
