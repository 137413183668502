.tabs__panel {
  display: none;
}

.tabs__panel--active {
  display: block;
}


.tabs {
  &__nav{
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0 0 60px;
    gap: 15px;
    &_btn{
      width: 200px;
      padding: 15px 0;
      &--active{
        color: var(--accent-color);
        background-color: var(--dark-color);
      }
      &:focus {
        color: var(--accent-color);
        background-color: var(--dark-color);
      }
      &:hover{
        color: var(--accent-color);
        background-color: var(--dark-color);
      }
    }
  }
}
