.card{
  width: 100%;
  height: 100%;
  perspective: 1000px;
  transform-style: preserve-3d;
  &__wrap{
    color: var(--accent-color);
  }
  &__item{
    position: relative;
    padding: 1px;
    border-radius: 15px;
    border: 1px solid rgba(27, 44, 62, 0.35);
    transition: transform 0.200s;
    &:hover {
      box-shadow: 0px 0px 25px 1px rgba(31, 57, 127, 0.35);
    }
  }
  &__img {
    display: block;
    width: 100%;
    height: 280px;
    border-radius: 0 0 15px 15px;
  }
  &__title {
    margin-bottom: 1px;
    border-radius: 15px 15px 0 0;
    background: var(--dark-color);
    color: var(--light-color);
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
  }
  &__text{
    border-radius: 15px;
    background: var(--dark-color);
    color: var(--light-color);
    padding: 15px;
    height: 175px;
  }
  &:hover .card__title {
    color: var(--accent-color);
  }
}

