.services{
  padding-top: 160px;
  &-top{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
  }
  &__title {
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 35px;
    margin-bottom: 30px;
  }
  &-description{
    width: 700px;
    text-align: center;
    font-size: 20px;
  }
  &-card{
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: repeat(4, 270px);
    grid-row-gap: 30px;
    margin-bottom: 80px;
    &__title{
      margin-top: 30px;
      text-align: center;
      font-size: 25px;
      margin-top: 60px;
      margin-bottom: 40px !important;
    }
    &_m{
      grid-template-rows:  repeat(2, 2fr);
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 0px;
    }
  }
  &__block{
    font-size: 20px;
    margin-bottom: 40px;
  }
  &__btn{
    margin: 0 auto;
  }
}
