.mapsite{
  &__list{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 2em;
  }
  &__item{
    &-title{
      margin-bottom: 10px;
    }
  }
  &__link{

    transition: .120s ease;
    &:hover {
      color: var(--accent-color);
    }
  }
}

.mapsite-sub{
  &__item{
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
  &__link{
    transition: .120s ease;
    &:hover {
      color: var(--accent-color);
    }
  }
}
