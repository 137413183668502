.equipment{
  &-title{
    margin-bottom: 60px;
  }
  &__item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:not(:last-child){
      margin-bottom: 40px;
    }
  }
  &__img{
    width: 50%;
    & img{

      padding: 2px;
      padding-top: 5px;
    }
  }
  &__content{
    background: var(--dark-color);
    width: 100%;
    border-radius: 0px 20px 20px 0px;
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__title{
    color: var(--accent-color);
    margin-bottom: 30px;
  }
  &__text {
    color: var(--light-color);
    font-size: 17px;

    & li{
      margin-left: 10px;
      margin-top: 10px;
    }
  }
  &__block{
    margin-bottom: 30px;
    &_content{
      display: flex;
      color: var(--light-color);
      &:not(:last-child){
        margin-bottom: 10px;
      }
      & h4{
        color: var(--accent-color);
        margin-right: 15px;
      }
    }
  }
}


// .wrapper__img{
//   width: 50%;
//   // &_mini{
//   //   display: grid;
//   //   grid-auto-flow: column;
//   //   grid-column-gap: 5px;
//   // }
// }

