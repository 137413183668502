.products{
  padding-top: 160px;
  &__title{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  &-top{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 80px;
  }
  &-description{
    width: 700px;
    text-align: center;
    font-size: 20px;
  }
  &-card {
    display: grid;
    justify-content: center;
    grid-column-gap: 30px;
    grid-template-columns: repeat(2, 270px);
    grid-row-gap: 30px;
    margin-bottom: 80px;
  }
  &__btn{
    margin: 0 auto;
  }
}
