.vacancies__text:not(:last-child){
  margin-bottom: 30px;
}

.vacancies-accordion{
  margin-top: 120px;
}

.vacancies-form{
  margin-top: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.vacancies-form__content{
  width: 400px;
  text-align: center;
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
  margin-right: 100px;
}

