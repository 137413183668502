@charset "UTF-8";
:root {
  --font-family: "Raleway", sans-serif;
  --content-width: 1170px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --accent-color: #FFA500;
  --text-color: #1B2C3E;
  --dark-color: #1B2C3E;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable value-keyword-case */
@font-face {
  font-family: "EBGaramond";
  src: url("../fonts/../fonts/EBGaramond-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
html,
body {
  box-sizing: border-box;
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
  font-weight: 400;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 18px;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
  color: var(--dark-color);
}

p {
  margin: 0;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  outline: none;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.section-title {
  font-family: "EBGaramond", sans-serif;
  font-size: 45px;
  font-weight: 700;
}

.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #fff;
  z-index: 1001;
}

.preloader__image {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*расположение (url) изображения gif и др. параметры*/
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}

.loaderi {
  overflow: hidden;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15em;
  margin-left: -15em;
  z-index: -1;
  text-indent: -9999em;
  width: 30em;
  height: 30em;
  border-radius: 50%;
  background: var(--dark-color);
  background: -moz-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: -webkit-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: -o-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: -ms-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: linear-gradient(to right, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  -webkit-animation: load3 2s infinite linear;
  animation: load3 2s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loaderi:before {
  width: 50%;
  height: 50%;
  background: var(--dark-color);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.loaderi:after {
  background: white;
  width: 85%;
  height: 85%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.nav {
  font-size: 17px;
  letter-spacing: 0.02em;
}
.nav li {
  position: relative;
  display: flex;
  align-items: center;
}
.nav__list {
  display: flex;
  align-items: center;
}
.nav__item a {
  color: var(--light-color);
}
.nav__item:not(:last-child) {
  margin-right: 15px;
}
.nav__link:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav__link_active {
  font-weight: 700;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.sub-nav {
  display: none;
  width: max-content;
  padding-top: 25px;
  transition: 0.6s ease;
  position: absolute;
  left: -5px;
  top: 12px;
}
.sub-nav__item {
  position: relative;
  border-radius: 5px;
  border: 1px solid var(--light-color);
  background-color: var(--dark-color);
  padding: 15px;
}
.sub-nav__item:not(:last-child) {
  margin-bottom: 3px;
}
.sub-nav__link {
  transition: 0.12s ease;
}
.sub-nav__link:hover {
  color: var(--accent-color);
}

.nav__item:hover .sub-nav {
  display: block;
}

.nav__arrow {
  width: 10px;
  height: 10px;
}

.arrow {
  margin-left: 5px;
  display: block;
  width: 10px;
  height: 0;
  border-left: 8px solid var(--accent-color);
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
}

.nav__item:hover .arrow {
  margin-top: 3px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid var(--accent-color);
}

.sub-nav__block {
  position: absolute;
  top: 0;
  left: 158px;
  display: none;
}

.sub-nav__item:hover .sub-nav__block {
  display: block;
}

.btn {
  display: block;
  width: 275px;
  text-align: center;
  position: relative;
  padding: 25px 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  color: var(--dark-color);
}
.btn:hover {
  color: var(--accent-color);
}
.btn::after, .btn::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 20%;
  border: 2px solid;
  transition: all 0.6s ease;
  border-radius: 1px;
}
.btn::after {
  bottom: 3px;
  right: 3px;
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: var(--dark-color);
  border-right-color: var(--dark-color);
}
.btn::before {
  top: 3px;
  left: 3px;
  border-bottom-color: transparent;
  border-right-color: transparent;
  border-top-color: var(--dark-color);
  border-left-color: var(--dark-color);
}
.btn-accent {
  color: var(--accent-color);
}
.btn-accent::after {
  border-bottom-color: var(--accent-color);
  border-right-color: var(--accent-color);
}
.btn-accent::before {
  border-top-color: var(--accent-color);
  border-left-color: var(--accent-color);
}
.btn:hover:after, .btn:hover:before {
  height: 100%;
  width: 100%;
}

.card {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  transform-style: preserve-3d;
}
.card__wrap {
  color: var(--accent-color);
}
.card__item {
  position: relative;
  padding: 1px;
  border-radius: 15px;
  border: 1px solid rgba(27, 44, 62, 0.35);
  transition: transform 0.2s;
}
.card__item:hover {
  box-shadow: 0px 0px 25px 1px rgba(31, 57, 127, 0.35);
}
.card__img {
  display: block;
  width: 100%;
  height: 280px;
  border-radius: 0 0 15px 15px;
}
.card__title {
  margin-bottom: 1px;
  border-radius: 15px 15px 0 0;
  background: var(--dark-color);
  color: var(--light-color);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  font-weight: 300;
  font-size: 18px;
}
.card__text {
  border-radius: 15px;
  background: var(--dark-color);
  color: var(--light-color);
  padding: 15px;
  height: 175px;
}
.card:hover .card__title {
  color: var(--accent-color);
}

.page-form {
  padding-top: 160px;
}

.form {
  position: relative;
  width: 500px;
  border: 2px solid var(--dark-color);
  border-radius: 50px 0px;
  padding: 40px 60px;
}
.form__title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 25px;
}
.form__label {
  display: flex;
  flex-direction: column-reverse;
}
.form__input {
  height: 35px;
  border-bottom: 1px solid var(--dark-color);
  margin-bottom: 15px;
}
.form__btn {
  margin: 15px auto 0;
}

.custom-file {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.custom-file input[type=file] {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}
.custom-file span {
  color: #868686;
}
.custom-file img {
  margin-left: 5px;
}

.upload-text {
  color: var(--accent-color);
  font-size: 17px;
}

.form-contact__area {
  margin-top: 15px;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.custom-checkbox__field {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.custom-checkbox__content {
  font-size: 16px;
}

.form-img {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
}

.form__block {
  display: none;
  color: var(--accent-color);
  text-align: center;
  margin-top: 15px;
}

/* strat modal */
.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(110, 156, 159, 0.95);
  z-index: 140;
}

.wrapper-modal {
  display: none;
  width: 100%;
  height: 100%;
}

.form-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 150;
}

.wrapper-modal__phone {
  display: none;
  width: 100%;
  height: 100%;
}

.form-modal__phone {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 150;
}

.form-phone__close {
  position: absolute;
  background: url("../img/close-phone.svg") no-repeat;
  width: 14.5px;
  height: 14.5px;
  left: 445px;
  top: 32px;
}

/* end modal */
.form-services {
  background: white;
  border: none;
}

.header {
  z-index: 200;
  background-image: linear-gradient(180deg, var(--dark-color) 0, rgba(0, 0, 0, 0) 100%);
  padding: 15px 0;
  position: sticky;
  top: 0;
}
.header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.header__contacts_phone {
  color: var(--accent-color) !important;
}
.header__contacts a {
  color: var(--light-color);
}
.header__contacts a:not(:last-child) {
  margin-bottom: 10px;
}
.header__btn {
  width: 185px;
  padding: 18px 0;
}
.header__navigation {
  display: flex;
  justify-content: center;
}

.header_fixed {
  border-bottom: 1px solid var(--light-color);
  background: var(--dark-color);
  transition: 0.5s;
}

.header-page {
  border-bottom: 1px solid var(--light-color);
  background: var(--dark-color);
}

.footer {
  background: var(--dark-color);
  padding: 60px 0;
}

.footer-navigation {
  display: flex;
  justify-content: space-between;
}

.footer-nav__wrapper:not(:last-child) {
  margin-right: 60px;
}
.footer-nav__list {
  display: flex;
  justify-content: space-between;
}
.footer-nav__item {
  display: flex;
  justify-content: space-between;
}
.footer-nav__item:not(:last-child) {
  margin-bottom: 10px;
}
.footer-nav__link {
  color: var(--light-color);
}
.footer-nav__link:hover {
  color: var(--accent-color);
}
.footer-nav__block:not(:last-child) {
  margin-bottom: 30px;
}

.footer-sub-nav__link {
  color: var(--light-color);
  font-size: 14px;
}
.footer-sub-nav__link:hover {
  color: var(--accent-color);
}

.footer-contacts {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #35485C;
  border-radius: 30px 0px;
  margin-top: 40px;
  padding: 10px 0;
}
.footer-contacts__item {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.footer-contacts__item:not(:last-child) {
  margin-right: 35px;
}
.footer-contacts__item a {
  color: var(--light-color);
}
.footer-contacts__separator {
  width: 0;
  height: 130px;
  border: 1px solid #FFFFFF;
}
.footer-contacts__icon {
  width: 40px;
  height: 50px;
  margin-bottom: 15px;
}
.footer-contacts__adress {
  width: 257px;
  text-align: center;
  color: var(--light-color);
}
.footer-contacts__mapsite {
  color: var(--light-color);
}

.copyright {
  margin-top: 40px;
  text-align: center;
  color: var(--light-color);
}

.hero {
  height: 90vh;
}
.hero__wrapper {
  border: 1px solid var(--light-color);
  border-radius: 15px;
  background: rgba(27, 44, 62, 0.4);
  margin-top: 160px;
  padding: 60px;
}
.hero__title {
  background-color: rgba(255, 165, 0, 0.8);
  padding: 15px;
  border: 10px dashed var(--dark-color);
  color: var(--dark-color);
  font-family: "Helvetica Neue";
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 50px;
}
.hero__title span {
  font-size: 70px;
  text-transform: uppercase;
}
.hero__btn {
  color: var(--light-color);
}
.hero__text {
  color: var(--accent-color);
  display: block;
  text-align: end;
  opacity: 0.5;
}

.video-vd {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first-screen-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-button-prev {
  left: 5px;
}

.swiper-button-next {
  right: 5px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px;
  color: var(--light-color);
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  font-size: 30px;
  color: var(--accent-color);
}

.arrow-down {
  margin-top: 70px;
}

.arrow-down span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 4px solid var(--accent-color);
  border-right: 4px solid var(--accent-color);
  transform: rotate(45deg);
  margin: 40px auto -50px auto;
  animation: arrow-down 2s infinite;
}

.arrow-down span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow-down span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes arrow-down {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
.services {
  padding-top: 160px;
}
.services-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}
.services__title {
  display: flex;
  text-align: center;
  align-items: center;
  font-size: 35px;
  margin-bottom: 30px;
}
.services-description {
  width: 700px;
  text-align: center;
  font-size: 20px;
}
.services-card {
  display: grid;
  grid-column-gap: 30px;
  grid-template-columns: repeat(4, 270px);
  grid-row-gap: 30px;
  margin-bottom: 80px;
}
.services-card__title {
  margin-top: 30px;
  text-align: center;
  font-size: 25px;
  margin-top: 60px;
  margin-bottom: 40px !important;
}
.services-card_m {
  grid-template-rows: repeat(2, 2fr);
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 0px;
}
.services__block {
  font-size: 20px;
  margin-bottom: 40px;
}
.services__btn {
  margin: 0 auto;
}

.products {
  padding-top: 160px;
}
.products__title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.products-top {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}
.products-description {
  width: 700px;
  text-align: center;
  font-size: 20px;
}
.products-card {
  display: grid;
  justify-content: center;
  grid-column-gap: 30px;
  grid-template-columns: repeat(2, 270px);
  grid-row-gap: 30px;
  margin-bottom: 80px;
}
.products__btn {
  margin: 0 auto;
}

.product-title {
  margin-bottom: 60px;
}
.product__block {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.product__block_img {
  margin-right: 35px;
}
.product__block_content {
  display: flex;
  align-items: center;
}
.product__block_content:not(:last-child) {
  margin-bottom: 10px;
}
.product__block_content h4 {
  padding: 10px;
  background-color: var(--dark-color);
  color: var(--accent-color);
  margin-right: 15px;
}
.product__block_content p {
  font-size: 17px;
}
.product__item:not(:last-child) {
  margin-bottom: 60px;
}
.product__text li:not(:last-child) {
  margin-bottom: 10px;
}

.section-gallery {
  padding-top: 160px;
  text-align: center;
}
.section-gallery__title {
  margin-bottom: 100px;
}

.gallery__list {
  padding: 0 15px;
  display: grid;
  justify-content: center;
  grid-column-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
  margin-bottom: 80px;
}
.gallery__item {
  position: relative;
  border-radius: 10px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  background-color: #000;
  overflow: hidden;
  transition: 0.5s;
}
.gallery__item:hover {
  transform: translateY(-5px);
}
.gallery__link {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 50;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  transition: 3s;
}
.gallery__img {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.gallery__img:hover {
  opacity: 0.8;
}
.gallery__titel {
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
  z-index: 1;
  text-transform: uppercase;
  transition: 0.5s;
}
.gallery__btn {
  margin: 0 auto;
}

.gallery__item:hover .gallery__titel {
  color: var(--accent-color);
}

.video {
  padding: 160px 0;
  text-align: center;
}
.video__title {
  margin-bottom: 100px;
}
.video__wrapper {
  width: 889px;
  margin: 0 auto;
}
.video__frame {
  border-radius: 15px;
  width: 100%;
  height: 500px;
}

.error-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.error-title {
  font-size: 40px;
  color: var(--accent-color);
}
.error__text {
  font-size: 35px;
  margin-left: 20px;
}
.error__link {
  text-decoration: underline;
}
.error__link:hover {
  color: var(--accent-color);
}

.page-top {
  padding: 130px 0 130px;
}
.page-title {
  font-size: 50px;
  margin-bottom: 30px;
}
.page-title__proucts {
  font-size: 40px;
}
.page-hero {
  margin-bottom: 80px;
}
.page-hero__title {
  width: max-content;
  padding: 5px 10px;
  background-color: var(--dark-color);
  color: var(--accent-color);
  font-size: 40px;
  text-transform: uppercase;
  margin-bottom: 50px;
}
.page-hero__text {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 60px;
  line-height: 1.1;
}
.page-list {
  display: flex;
  align-items: center;
  margin-bottom: 130px;
}
.page-list__products {
  font-size: 16px;
  margin-bottom: 60px;
}
.page-list__item:not(:last-child) {
  margin-right: 13px;
}
.page-list__end {
  color: #919191;
}

.contacts-map {
  border: 1px solid var(--dark-color);
  margin-top: 130px;
}
.contacts__item {
  display: flex;
  align-items: center;
}
.contacts__item:not(:last-child) {
  margin-bottom: 35px;
}
.contacts__item_title {
  width: 265px;
}
.contacts__item_tel:not(:last-child) {
  margin-right: 20px;
}
.contacts__item a {
  color: var(--dark-color);
}
.contacts__item_none {
  display: none;
}

.urid {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.urid__content {
  margin-left: 20px;
}

.tabs__panel {
  display: none;
}

.tabs__panel--active {
  display: block;
}

.tabs__nav {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0 0 60px;
  gap: 15px;
}
.tabs__nav_btn {
  width: 200px;
  padding: 15px 0;
}
.tabs__nav_btn--active {
  color: var(--accent-color);
  background-color: var(--dark-color);
}
.tabs__nav_btn:focus {
  color: var(--accent-color);
  background-color: var(--dark-color);
}
.tabs__nav_btn:hover {
  color: var(--accent-color);
  background-color: var(--dark-color);
}

.vacancies__text:not(:last-child) {
  margin-bottom: 30px;
}

.vacancies-accordion {
  margin-top: 120px;
}

.vacancies-form {
  margin-top: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.vacancies-form__content {
  width: 400px;
  text-align: center;
  font-weight: 400;
  font-size: 30px;
  line-height: 140%;
  margin-right: 100px;
}

.accordion {
  padding: 15px;
  border: 2px dashed var(--dark-color);
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
}
.accordion:hover {
  box-shadow: 0px 0px 25px 1px rgba(31, 57, 127, 0.35);
}
.accordion__title {
  font-weight: 500;
  font-size: 24px;
}
.accordion__content {
  max-height: 0;
  overflow: hidden;
  transition: 0.8s;
}
.accordion__content_text {
  font-family: "Space Grotesk";
  padding: 20px;
}

.accordion svg {
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
}

.company-block {
  display: flex;
}
.company-section:not(:last-child) {
  margin-bottom: 30px;
}
.company__img {
  width: 30%;
  border-radius: 15px;
  margin-right: 20px;
}
.company__title {
  margin-bottom: 10px;
}
.company__text {
  font-size: 20px;
}
.company__list {
  margin: 10px 0;
}

.documentation__list {
  margin-top: 30px;
}
.documentation__item {
  display: flex;
  align-items: center;
  padding: 35px 0;
}
.documentation__item:not(:last-child) {
  border-bottom: 1px solid var(--dark-color);
}
.documentation__title {
  width: 250px;
  margin-right: 60px;
}
.documentation__title_m {
  margin-top: 15px;
  font-size: 16px;
}
.documentation__img {
  width: 150px;
}

.section-gallery {
  padding-top: 160px;
  text-align: center;
}
.section-gallery__title {
  margin-bottom: 100px;
}

.gallery-page__list {
  display: grid;
  justify-content: center;
  grid-column-gap: 15px;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 15px;
}
.gallery-page__item {
  position: relative;
  border-radius: 10px;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  background-color: #000;
  overflow: hidden;
  transition: 0.5s;
}
.gallery-page__item:hover {
  transform: translateY(-5px);
}
.gallery-page__link {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 50;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  transition: 3s;
}
.gallery-page__img {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.gallery-page__img:hover {
  opacity: 0.8;
}
.gallery-page__titel {
  color: #fff;
  text-align: left;
  margin-bottom: 10px;
  z-index: 1;
  text-transform: uppercase;
  transition: 0.5s;
}

.gallery-page__item:hover .gallery-page__titel {
  color: var(--accent-color);
}

.gallery-img__list {
  display: grid;
  justify-content: center;
  grid-column-gap: 15px;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 15px;
}
.gallery-img__item {
  position: relative;
  border-radius: 10px;
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  background-color: #000;
  overflow: hidden;
  transition: 0.5s;
}
.gallery-img__item:hover {
  transform: translateY(-5px);
}
.gallery-img__link {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  z-index: 50;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  transition: 3s;
}
.gallery-img__img {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.equipment-title {
  margin-bottom: 60px;
}
.equipment__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.equipment__item:not(:last-child) {
  margin-bottom: 40px;
}
.equipment__img {
  width: 50%;
}
.equipment__img img {
  padding: 2px;
  padding-top: 5px;
}
.equipment__content {
  background: var(--dark-color);
  width: 100%;
  border-radius: 0px 20px 20px 0px;
  padding: 30px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.equipment__title {
  color: var(--accent-color);
  margin-bottom: 30px;
}
.equipment__text {
  color: var(--light-color);
  font-size: 17px;
}
.equipment__text li {
  margin-left: 10px;
  margin-top: 10px;
}
.equipment__block {
  margin-bottom: 30px;
}
.equipment__block_content {
  display: flex;
  color: var(--light-color);
}
.equipment__block_content:not(:last-child) {
  margin-bottom: 10px;
}
.equipment__block_content h4 {
  color: var(--accent-color);
  margin-right: 15px;
}

.description {
  margin-bottom: 60px;
}
.description-wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}
.description__img {
  width: 30%;
  border-radius: 15px;
}
.description__content {
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.description__content p {
  font-family: Inter, Arial, sans-serif;
  line-height: 30px;
  font-size: 25px;
  text-indent: 40px;
}
.description__btn {
  bottom: 0;
}
.description p {
  font-size: 20px;
}
.description p:not(:Last-child) {
  margin-bottom: 20px;
}

.block__title {
  margin-bottom: 20px;
}
.block__link {
  color: var(--accent-color);
}
.block__content {
  margin-bottom: 35px;
}

.lab-btn {
  margin-bottom: 20px;
}

.mapsite__list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 2em;
}
.mapsite__item-title {
  margin-bottom: 10px;
}
.mapsite__link {
  transition: 0.12s ease;
}
.mapsite__link:hover {
  color: var(--accent-color);
}

.mapsite-sub__item:not(:last-child) {
  margin-bottom: 5px;
}
.mapsite-sub__link {
  transition: 0.12s ease;
}
.mapsite-sub__link:hover {
  color: var(--accent-color);
}