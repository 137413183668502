.header{
  z-index: 200;
  background-image: linear-gradient(180deg, var(--dark-color) 0, rgba(0, 0, 0, 0) 100%);

  padding: 15px 0;
  position: sticky;
  top: 0;
  &__top{
    @include flex-all-sb;
  }
  &__contacts{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &_phone {
        color: var(--accent-color) !important;
      }
    & a {
    color: var(--light-color);
      &:not(:last-child){
        margin-bottom: 10px;
      }
    }
  }
  &__btn{
    width: 185px;
    padding: 18px 0;
  }
  &__navigation{
    @include flex-h-center;
  }
}

.header_fixed{
  border-bottom: 1px solid var(--light-color);
  background: var(--dark-color);
  transition: 0.5s;
}

.header-page{
  border-bottom: 1px solid var(--light-color);
  background: var(--dark-color);
}

