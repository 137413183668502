.page{
  &-top{
    padding: 130px 0 130px;
  }
  &-title{
    font-size: 50px;
    margin-bottom: 30px;
    &__proucts{
      font-size: 40px;
    }
  }
  &-hero{
    margin-bottom:80px;
    &__title{
      width: max-content;
      padding: 5px 10px;
      background-color: var(--dark-color);
      color: var(--accent-color);
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom:50px;
    }
    &__text{
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 60px;
      line-height: 1.1;
    }
  }
  &-list{
    display: flex;
    align-items: center;
    margin-bottom: 130px;
    &__products{
      font-size: 16px;
      margin-bottom: 60px;
    }
    &__item:not(:last-child) {
      margin-right: 13px;
    }
    &__end {
      color: #919191;
    }

  }
}

