.video {
  padding: 160px 0;
  text-align: center;
  &__title {
    margin-bottom: 100px;
  }
  &__wrapper {

    width: 889px;
    margin: 0 auto;
  }
  &__frame {
    border-radius: 15px;
    width: 100%;
    height: 500px;
  }
}
