.description{
  &-wrapper{
    display: flex;
    width: 100%;
    margin-bottom: 30px;
  }
  &__img{
    width: 30%;
    border-radius: 15px;
  }
  &__content{
    margin: 20px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__content p{
    font-family: Inter, Arial, sans-serif;
    line-height: 30px;
    font-size: 25px;
    text-indent: 40px;
  }
  &__btn{
    bottom: 0;
  }
  margin-bottom: 60px;
  & p{
    font-size: 20px;
    &:not(:Last-child){
      margin-bottom: 20px;
    }
  }
}

.block{
  &__title{
    margin-bottom: 20px;
  }
  &__link{
    color: var(--accent-color);
  }
  &__content{
    margin-bottom: 35px;
  }
}

.lab-btn{
  margin-bottom: 20px;
}
