.error{
  &-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }
  &-title {
    font-size: 40px;
    color: var(--accent-color);
  }
  &__text{
    font-size: 35px;
    margin-left: 20px;
  }
  &__link{
    text-decoration: underline;
    &:hover{
      color: var(--accent-color);
    }
  }
}

