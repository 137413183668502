.footer{
  background: var(--dark-color);
  padding: 60px 0;
}
.footer-navigation{
  display: flex;
  justify-content: space-between;
}
.footer-nav{
  &__wrapper{
    &:not(:last-child){
      margin-right: 60px;
    }
  }
  &__list{
    display: flex;
    justify-content: space-between;
  }
  &__item{
    display: flex;
    justify-content: space-between;
    &:not(:last-child){
      margin-bottom: 10px;
    }
  }
  &__link{
    color: var(--light-color);
    &:hover {
      color: var(--accent-color);
    }
  }
  &__block{
    &:not(:last-child){
      margin-bottom: 30px;
    }
  }
}
.footer-sub-nav{
  &__link{
    color: var(--light-color);
    font-size: 14px;
    &:hover {
      color: var(--accent-color);
    }
  }
}

.footer-contacts{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #35485C;
  border-radius: 30px 0px;
  margin-top: 40px;
  padding: 10px 0;
  &__item{
    display: flex;
    align-items: center;
    flex-direction: column;
    &:not(:last-child) {
      margin-right: 35px;
    }
    a{
      color: var(--light-color);
    }
  }
  &__separator {
    width: 0;
    height: 130px;
    border: 1px solid #FFFFFF;
  }
  &__icon{
    width: 40px;
    height: 50px;
    margin-bottom: 15px;
  }
  &__adress {
    width: 257px;
    text-align: center;
    color: var(--light-color);
  }
  &__mapsite{
    color: var(--light-color);
  }
}

.copyright{
  margin-top: 40px;
  text-align: center;
  color: var(--light-color);
}

