.documentation{
  &__list{
    margin-top: 30px;
  }
  &__item{
    display: flex;
    align-items: center;
    padding: 35px 0;
    &:not(:last-child){
      border-bottom: 1px solid var(--dark-color);
    }
  }
  &__title {
    width: 250px;
    margin-right: 60px;
    &_m{
      margin-top: 15px;
      font-size: 16px;
    }
  }
  &__img{
    width: 150px;
  }
}
