.section-gallery{
  padding-top: 160px;
  text-align: center;
  &__title{
    margin-bottom: 100px;
  }
}

.gallery{
&__list{
    padding: 0 15px;
    display: grid;
    justify-content: center;
    grid-column-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 15px;
    margin-bottom: 80px;
  }
  &__item{
    position: relative;
    border-radius: 10px;
    min-height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    background-color: #000;
    overflow: hidden;
    transition: .5s;
    &:hover{
      transform: translateY(-5px)
    }
  }
  &__link{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 50;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    transition: 3s;
  }
  &__img{
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &:hover{
      opacity: .8;
    }
  }
  &__titel {
    color: #fff;
    text-align: left;
    margin-bottom: 10px;
    z-index: 1;
    text-transform: uppercase;
    transition: .5s;
  }
  &__btn {
    margin: 0 auto;
  }
}

.gallery__item:hover .gallery__titel{
  color: var(--accent-color);
}
