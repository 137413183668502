.hero{
  height: 90vh;
  &__wrapper{
    border: 1px solid var(--light-color);
    border-radius: 15px;
    background: rgba(27, 44, 62, 0.4);
    margin-top: 160px;
    padding: 60px;
  }
  &__block{


  }
  &__title{
    background-color: rgba(255, 165, 0, 0.8);
    padding: 15px;
    border: 10px dashed var(--dark-color);
    color: var(--dark-color);
    font-family: "Helvetica Neue";
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 50px;
    & span {
      font-size: 70px;
      text-transform: uppercase;
    }
  }
  &__btn{
    color: var(--light-color);
    // &:hover {
    //   color: var(--darck-color);
    // }
  }
  &__text{
    color: var(--accent-color);
    display: block;
    text-align: end;
    opacity: 0.5;
  }
}
.video-vd {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.first-screen-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.swiper {
  width: 100%;
  height: auto;
}
.swiper-button-prev {
  left: 5px;
}
.swiper-button-next{
  right: 5px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px;
  color: var(--light-color);
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  font-size: 30px;
  color: var(--accent-color);
}



.arrow-down {
  margin-top: 70px;
}

.arrow-down span {
  display: block;
  width: 30px;
  height: 30px;
  border-bottom: 4px solid var(--accent-color);
  border-right: 4px solid var(--accent-color);
  transform: rotate(45deg);
  margin: 40px auto -50px auto;
  animation: arrow-down 2s infinite;
}

.arrow-down span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow-down span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes arrow-down {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
