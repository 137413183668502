/* strat modal */
.overlay-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(110, 156, 159, 0.95);
  z-index: 140;
}
.wrapper-modal{
  display: none;
  width: 100%;
  height: 100%;
}
.form-modal{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: auto;
  z-index: 150;
}
.wrapper-modal__phone{
  display: none;
  width: 100%;
  height: 100%;
}
.form-modal__phone{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: auto;
  height: auto;
  z-index: 150;
}
.form-phone__close{
  position: absolute;
  background: url('../img/close-phone.svg') no-repeat;
  width: 14.5px;
  height: 14.5px;
  left: 445px;
  top: 32px;
}

/* end modal */

.form-services{
  background: white;
  border: none;
}
