.company{
  &-block{
    display: flex;
  }
  &-section{
    &:not(:last-child){
      margin-bottom: 30px;
    }
  }
  &__img{
    width: 30%;
    border-radius: 15px;
    margin-right: 20px;
  }
  &__title{
    margin-bottom: 10px;
  }
  &__text{
    font-size: 20px;
  }
  &__list{
    margin: 10px 0;
  }
}
