
.btn {
  display: block;
  width: 275px;
  text-align: center;
  position: relative;
  padding: 25px 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  font-size: 20px;
  color: var(--dark-color);
  &:hover{
    color: var(--accent-color);
  }
  &::after,
  &::before{
    content: '';
    position: absolute;
    width: 20%;
    height: 20%;
    border: 2px solid;
    transition: all 0.6s ease;
    border-radius: 1px;
  }
  &::after{
    bottom: 3px;
    right: 3px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-bottom-color: var(--dark-color);
    border-right-color: var(--dark-color);
  }
  &::before{
    top: 3px;
    left: 3px;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-top-color: var(--dark-color);
    border-left-color: var(--dark-color);
  }
  &-accent{
    color: var(--accent-color);
  }
  &-accent::after {
    border-bottom-color: var(--accent-color);
    border-right-color: var(--accent-color);
  }
  &-accent::before {
    border-top-color: var(--accent-color);
    border-left-color: var(--accent-color);
  }
  &:hover:after,
  &:hover:before{
    height: 100%;
    width: 100% ;
  }
}





