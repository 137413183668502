.product{
  &-title{
    margin-bottom: 60px;
  }
  &__block{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    &_img{
      margin-right: 35px;
    }
    &_content{
      display: flex;
      align-items: center;
      &:not(:last-child){
        margin-bottom: 10px;
      }
      & h4{
        padding: 10px;
        background-color: var(--dark-color);
        color: var(--accent-color);
        margin-right: 15px;
      }
      & p{
        font-size: 17px;
      }
    }
  }
  &__item{
    &:not(:last-child){
      margin-bottom: 60px;
    }
  }
  &__text li:not(:last-child){
    margin-bottom: 10px;
  }
}

