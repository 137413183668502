.page-form{
  padding-top: 160px;
}

.form{
  position: relative;
  width: 500px;
  border: 2px solid var(--dark-color);
  border-radius: 50px 0px;
  padding: 40px 60px;
  &__title{
    text-align: center;
    margin-bottom: 30px;
    font-size: 25px;
  }
  &__label{
    display: flex;
    flex-direction: column-reverse;
  }
  &__input{
    height: 35px;
    border-bottom: 1px solid var(--dark-color);
    margin-bottom: 15px;
  }
  &__btn{
    margin: 15px auto 0;
  }
}

.custom-file{
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & input[type="file"]{
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
  }
  & span{
    color: #868686;
  }
  & img{
    margin-left: 5px;
  }
}

.upload-text{
  color: var(--accent-color);
  font-size: 17px;
}

.form-contact__area{
  margin-top: 15px;
}

.custom-checkbox{
  display: flex;
  align-items: center;
  margin: 15px 0;
}
.custom-checkbox__field{
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.custom-checkbox__content{
  font-size: 16px;
}

.form-img{
    position: absolute;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
}

.form__block{
  display: none;
  color: var(--accent-color);
  text-align: center;
  margin-top: 15px;
}
