.nav{
  font-size: 17px;
    letter-spacing: 0.02em;
  & li{
    position: relative;
    display: flex;
    align-items: center;
  }
  &__list{
    display: flex;
    align-items: center;
  }
  &__item{
    a{
      color: var(--light-color);
    }
    &:not(:last-child){
      margin-right: 15px;
    }
  }
  &__link{
    &:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
  &__link_active{
    font-weight: 700;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
}

.sub-nav{
  display: none;
  width: max-content;
  padding-top: 25px;
  transition: .60s ease;
  position: absolute;
  left: -5px;
  top: 12px;
  &__item{
    position: relative;
    border-radius: 5px;
    border: 1px solid var(--light-color);
    background-color: var(--dark-color);
    padding: 15px;
    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }
  &__link{
    transition: .120s ease;
    &:hover {
      color: var(--accent-color);
    }
  }
}
.nav__item:hover .sub-nav{
  display: block;
}

.nav__arrow{
  width: 10px;
  height: 10px;
}
.arrow {
  margin-left: 5px;
  display: block;
  width: 10px;
  height: 0;
  border-left: 8px solid var(--accent-color);
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
}
.nav__item:hover .arrow {
  margin-top: 3px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid var(--accent-color);
}

.sub-nav__block{
  position: absolute;
  top: 0;
  left: 158px;
  display: none;
}
.sub-nav__item:hover .sub-nav__block{
  display: block;
}
