.gallery-img{
&__list{
    display: grid;
    justify-content: center;
    grid-column-gap: 15px;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 15px;
  }
  &__item{
    position: relative;
    border-radius: 10px;
    min-height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    background-color: #000;
    overflow: hidden;
    transition: .5s;
    &:hover{
      transform: translateY(-5px)
    }
  }
  &__link{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    z-index: 50;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    transition: 3s;
  }
  &__img{
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

