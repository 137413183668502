.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background: #fff;
  z-index: 1001;
}

.preloader__image {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*расположение (url) изображения gif и др. параметры*/
}

.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}

.loaded .preloader {
  display: none;
}

.loaderi {
  overflow: hidden;
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15em;
  margin-left: -15em;
  z-index: -1;
  text-indent: -9999em;
  width: 30em;
  height: 30em;
  border-radius: 50%;
  background: var(--dark-color);
  background: -moz-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: -webkit-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: -o-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: -ms-linear-gradient(left, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  background: linear-gradient(to right, var(--dark-color) 10%, rgba(101, 171, 248, 0) 42%);
  -webkit-animation: load3 2s infinite linear;
  animation: load3 2s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loaderi:before {
  width: 50%;
  height: 50%;
  background: var(--dark-color);
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}

.loaderi:after {
  background: white;
  width: 85%;
  height: 85%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
