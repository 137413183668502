.accordion {
  padding: 15px;
  border: 2px dashed var(--dark-color);
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 25px 1px rgba(31, 57, 127, 0.35);
  }
  &__title {
    font-weight: 500;
    font-size: 24px;
  }
  &__content {
    max-height: 0;
    overflow: hidden;
    transition: 0.8s;
    &_text {
      font-family: 'Space Grotesk';
      padding: 20px;
    }
  }
}


.accordion svg {
  position: absolute;
  right: 24px;
  top: 16px;
  cursor: pointer;
}
